import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertComponent } from './alert.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from './confirm.component';

@Injectable()
export class HelperService {

  constructor(private modalService: BsModalService) {
  }


  getResponseError(error: HttpErrorResponse) {
    const errorMap = (error) as HttpErrorResponse;
    const errorObj = Object.assign({}, error.error);
    if (errorObj.errorMessage) {
      return errorObj.errorMessage;
    }
    return 'An error occured. Please try again later.';
    // return JSON.stringify(errorObj);
  }


  validUrl(value: string): string {
    const filter: RegExp = /^(https?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (value.trim() === '') {
      return '';
    }
    if (filter.test(value)) {
      return '';
    }
    else {
      return 'Url is not valid';
    }
  }

  validEmail(value: string): string {
    const filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (value.trim() === '') {
      return 'User name is required';
    }
    if (filter.test(value)) {
      return '';
    }
    else {
      return 'User name should be a valid email address';
    }
  }

  showAlert(title?: string, message?: string) {
    const modalConfig = { animated: false, keyboard: true, backdrop: true, ignoreBackdropClick: true};
    const initialState = {title, message};
    const bsModalRef = this.modalService.show(AlertComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }));
  }

  showConfirm(title?: string, message?: string) {
    const modalConfig = { animated: false, keyboard: true, backdrop: true, ignoreBackdropClick: true};
    const initialState = {title, message};
    const bsModalRef = this.modalService.show(ConfirmComponent, Object.assign({}, modalConfig, { class: 'modal-sm', initialState }));
    return new Promise<boolean>((resolve, reject) => bsModalRef.content.onClose.subscribe((result) => resolve(result) ));
  }

}
