import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

export interface ConfirmModel {
  title: string;
  message: string;
}

@Component({
  selector: 'app-confirm',
  template: `<div class="modal-dialog" style="min-width:350px;">
                <div class="modal-content">
                   <div class="modal-header">
                     <h4 class="modal-title">{{title || 'Confirm'}}</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
                    <span aria-hidden="true">&times;</span>
                    </button>
                   </div>
                   <div class="modal-body">
                     <p>{{message || 'Are you sure?'}}</p>
                   </div>
                   <div class="modal-footer">
                     <button type="button" autofocus class="btn btn-primary" (click)="confirm()">Yes</button>
                     <button type="button" class="btn btn-default" (click)="cancel()">No</button>
                   </div>
                 </div>
                </div>`
})
export class ConfirmComponent {
  title: string;
  message: string;

  public onClose: Subject<boolean>;

  constructor(public bsModalRef: BsModalRef) {
    this.onClose = new Subject();
  }
  confirm() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  cancel() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
