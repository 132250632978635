<div class="container" *ngIf="currentStep == 1">
    <div class="row justify-content-center" style="margin-left: 10px; margin-right: 10px;">
        <h1>
            <img [hidden]="selBusiness?.businessLogo.trim() ==''" height="30" style="vertical-align: middle;" [src]="selBusiness?.businessLogo"/>
            <span style="vertical-align: middle;">&nbsp;&nbsp;{{selBusiness?.name}}</span>
        </h1>
    </div>
    <div class="row justify-content-center address" style="margin-left: 10px; margin-right: 10px;">
        <span style="vertical-align: middle;">{{selBusiness?.addr}}</span>
    </div>
    <hr />
    <div style="color:red;">{{this.busError}}</div>
    <div style="color:red;" *ngIf="lstDepartments?.length == 0">No active queue(s) found!</div>
    <div *ngFor="let department of lstDepartments" style="padding:25px;margin-top:15px;margin-bottom: 15px;" 
        [ngStyle]="{'background-color':department.qStatus == true ? '#f8f8f8' : '#d9d9d9'}">
        <div class="row">
            <div class="col-8">
                <div class="department" *ngIf="department.name != 'Default'">
                    Queue for: <span style="font-weight: bolder;">{{department.name}}</span>
                </div>
                <div class="department" *ngIf="department.name == 'Default'">
                    <span style="font-weight: bolder;">Queue (Main)</span>
                </div>
            </div>
            <div class="col-4">
                <div class="qStatus" [ngStyle]="{'color':department.qStatus == true ? 'green' : 'red'}">
                    <span class="dot" style="margin-right: 5px;"
                        [ngStyle]="{'background-color':department.qStatus == true ? 'green' : 'red'}"></span>{{department.qStatus == true ? 'Open' : 'Closed'}}
                </div>
            </div>
        </div>
        
        <div [hidden]="selBusiness?.addr.trim() == department.address.trim()" class="address">{{department.address}}</div>

        <div *ngIf="checkInAllowed(department)" style="margin-top: 10px;">
            <div class="container">
                <div class="row top-buffer">
                    <div class="col-6 text-center">
                        <span class="waitTime">{{department.waitTime * department.queueLength}}</span> min(s)
                    </div>
                    <div class="col-6 text-center">
                        <span class="queueLength">{{department.queueLength}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 text-center">
                        <span class="waitTimeLabel">Approx wait time</span>
                    </div>
                    <div class="col-6 text-center">
                        <span class="queueLengthLabel">Queue length</span>
                    </div>
                </div>
                <div class="row top-buffer justify-content-center">
                    <button type="button" (click)="selectDepartment(department)" class="btn btn-info button-size">
                        {{ department.services?.length > 0 ? 'Select service & check-in' : 'Check-in'}}
                    </button>
                </div>
            </div>
        </div>
        <div class="queueMessage">
            {{department.deptError}}
        </div>
    </div>
</div>
<div class="container" *ngIf="currentStep == 2" style="margin-bottom: 10px; padding-left: 20px; padding-right: 20px;">
    <form #checkInForm="ngForm" novalidate>
        <div class="row justify-content-center">
            <h1>
                <img [hidden]="selBusiness?.businessLogo.trim() ==''" height="30" style="vertical-align: middle;" [src]="selBusiness?.businessLogo"/>
                <span style="vertical-align: middle;">&nbsp;&nbsp;{{selBusiness?.name}}</span>
            </h1>
        </div>
        <hr />
        <div class="row">
            <div class="col-8">
                <div class="department" *ngIf="selDepartment?.name != 'Default'">
                    Queue for: <span style="font-weight: bolder;">{{selDepartment?.name}}</span>
                </div>
                <div class="department" *ngIf="selDepartment?.name == 'Default'">
                    <span style="font-weight: bolder;">Queue (Main)</span>
                </div>
            </div>
            <div class="col-4">
                <div class="qStatus" [ngStyle]="{'color':selDepartment?.qStatus == true ? 'green' : 'red'}">
                    <span class="dot" style="margin-right: 5px;"
                        [ngStyle]="{'background-color':selDepartment?.qStatus == true ? 'green' : 'red'}">
                    </span>
                    {{selDepartment?.qStatus == true ? 'Open' : 'Closed'}}
                </div>
            </div>
        </div>

        <div class="address">{{selDepartment?.address}}</div>

        <div style="margin-top: 15px;">
            <br/>
            <div class="form-header">Contact details</div>
            <div class="form-group center">
                <div class="form-label" style="margin-top: 10px;">
                    <label for="check_in_user"><span style="color:red;">(*)</span> Contact name</label>
                    <input #check_in_user="ngModel" type="text" class="form-control" style="width:80%;" maxlength="255"
                        name="check_in_user" [(ngModel)]="checkInUser.name" required />
                </div>
            </div>
            <div class="form-group center">
                <div class="form-label" style="margin-top: 10px;">
                    <label for="check_in_email">Email address</label>
                    <input type="email" class="form-control" style="width:80%;" name="check_in_email" maxlength="255"
                        #check_in_email="ngModel" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                        [(ngModel)]="checkInUser.email" />
                </div>
                <small class="form-text text-muted" [ngClass]="(check_in_email.invalid) ? 'error' : '' ">
                    <div class="form-label-message">* Required for email notification</div>
                </small>
            </div>
            <div class="form-group center">
                <div class="form-label" style="margin-top: 10px;">
                    <label for="check_in_phone">Phone number</label><br />
                    <input type="text" class="form-control" style="width:80%;" name="check_in_phone" intlTelInput
                        [country]="selBusinessInfo.countryCode" placeholder="   "
                        (selectPhoneNumber)="getIntlNumber($event)" #check_in_phone="ngModel"
                        pattern="\(?\d{3}\)?[-]? *\d{3}[-\.]? *[-]?\d{4}" [(ngModel)]="checkInUser.localPhoneNumber" />
                </div>
                <small class="form-text text-muted" [ngClass]="(check_in_phone.invalid) ? 'error' : '' ">
                    <div class="form-label-message" *ngIf="smsAllowed(selDepartment)">* Required for SMS notification</div>
                </small>
            </div>
            <div *ngIf="selDepartment?.allowMorePeopleInACheckin">
                <br/>
                <div class="form-header">Check-in headcount</div>
                <div class="form-label center">Total people checking in (including you)&nbsp;
                    <input type="number" style="text-align: right;" [min]="1" [max]="selDepartment?.maxHeadCount" value="1" name="head_count"
                        #check_in_head_count="ngModel" pattern="^[1-9][0-9]*$" [(ngModel)]="checkInUser.headCount" />
                </div>
                <small class="form-text text-muted center">
                    <div class="form-label-message">* Maximum {{selDepartment?.maxHeadCount}} people allowed for check-in.</div>
                </small>
            </div>
        </div>
        <div *ngIf="selDepartment?.services.length > 0" style="margin-top: 10px;">
            <br/>
            <div class="form-header">Service details</div>
            <small class="form-text text-muted">
                <div class="form-label-message center">* Atleast one service needs to be selected.</div>
            </small>
            <div class="center">
                <accordion [isAnimated]="true" style="margin: 10px 0 10px 0;">
                    <accordion-group *ngFor="let service of selDepartment?.services;let i=index"
                        [isOpen]="service.selected && service.questions.length >0">
                        <div accordion-heading>
                            <div class="form-label">
                                <input type="checkbox" [id]="'check'+i" [name]="'check'+i" [(ngModel)]="service.selected"
                                    style="width:14px;height:14px;" (click)="serviceSelection($event)" />
                                &nbsp;{{service?.name}}
                            </div>
                        </div>
                        <div *ngFor="let question of service?.questions;let j=index" style="margin-bottom: 10px;">
                            <div class="form-label">
                                <span style="color:red;" *ngIf="question.mandatory">(*)</span>
                                {{question?.question}}
                            </div>
                            <input [disabled]="!service.selected" type="text" [id]="'t'+j" [name]="'t'+j" style="width:80%;"
                                [(ngModel)]="question.answer" />
                            <br />
                        </div>
                    </accordion-group>
                </accordion>
            </div>
        </div>
        <br/>
        <div class="form-label">
            Get notified through&nbsp;
            <input type="radio" name="radio" id="radio1" [value]="1" [checked]="checkInUser.notifyUsing==0"
                [(ngModel)]="checkInUser.notifyUsing"><label>&nbsp;email&nbsp;&nbsp;</label>
            <input type="radio" name="radio" id="radio2" [value]="2" [checked]="checkInUser.notifyUsing==1"
                [(ngModel)]="checkInUser.notifyUsing" *ngIf="smsAllowed(selDepartment)"><label  *ngIf="smsAllowed(selDepartment)">&nbsp;or SMS&nbsp;&nbsp;</label>
            <input type="radio" name="radio" id="radio3" [value]="3" [checked]="checkInUser.notifyUsing==2"
                [(ngModel)]="checkInUser.notifyUsing"  *ngIf="smsAllowed(selDepartment)"><label  *ngIf="smsAllowed(selDepartment)">&nbsp;or both.</label> <br />
        </div>
        <br/>
        <small class="form-text text-muted">
            <div class="form-label-message">* By providing your phone number and/or email address, you are providing consent to receive text messages and/or emails with information about your queue status.</div>
        </small>

        <div class="row top-buffer justify-content-center">
            <button type="button" class="btn btn-info button-size" (click)="processCheckIn()" [disabled]=" check_in_user.invalid || 
                ( check_in_email.invalid || check_in_phone.invalid) ||
                ( check_in_email.value?.trim() == '' && check_in_phone.value?.trim() =='') || submitted == true">
                Check-in
            </button>
        </div>


        <div class="row top-buffer justify-content-center" style="color:red;">{{this.checkInError}}</div>
        <br/>
    </form>
</div>