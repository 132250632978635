
<nav class="navbar navbar-expand-sm  d-flex" style="padding-top:0px;" >

    <div>
        <!-- Brand -->
        <a class="navbar-brand js-scroll-trigger" href="#page-top"></a>
    </div>

    <div class="flex-grow-1 align-content-center text-center">
        <div *ngIf="true" class="d-none d-md-block" 
            style="z-index:0;">
        </div>

        <div *ngIf="true" class="d-none d-sm-block" style="z-index:0;">
            <h4></h4>
        </div>
    </div>

</nav>