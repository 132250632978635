import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { IBusiness, IBusinessInfo } from 'src/app/models/business';
import { IDepartment } from 'src/app/models/department';
import { IQueueInfo } from 'src/app/models/queue-info';

import { LoadingService } from 'src/app/services/loading.service';
import { forkJoin, Observable } from 'rxjs';
import { CheckInService } from 'src/app/services/check-in.service';
import { map, filter } from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    constructor(public router: Router,
                public route: ActivatedRoute,
                private _loadingindicator: LoadingService,
                private checkInService: CheckInService)
    {}
    private businessId: string;
    private departmentId: string;
    public selBusinessInfo: IBusinessInfo;
    public selBusiness: IBusiness;
    public selDepartment: IDepartment;
    public queueInfo$: Observable<IQueueInfo[]>;
    public activeQueue$: Observable<IQueueInfo[]>;
    public waitQueue$: Observable<IQueueInfo[]>;
    public departmentInfo$: Observable<any>;

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.businessId = params.get('business');
            this.departmentId = params.get('department') || '';
            if (this.businessId && this.departmentId) {
                this.checkInService.getBusinessRegInfo(this.businessId)
                    .then((data: IBusinessInfo) => {
                        this.selBusinessInfo = data;
                        console.log('Business Data:', this.selBusinessInfo);
                        // Bussiness logic
                        if ( !this.selBusinessInfo || !this.selBusinessInfo.planIsActive || !this.selBusinessInfo.isActive) {
                            // this.busError = 'Web check-in not available. Please contact the business. Ref: BNAOPE'; // This business is no longer active or the plan has expired
                            // this._loadingindicator.isBusy = false;
                            // return;
                        }
                        if (this.selBusinessInfo.planId === '0free' &&
                            this.selBusinessInfo.currentQueueLimit >= this.selBusinessInfo.maxQueueLimit ){
                            // this.busError = 'Web check-in not available. Please contact the business. Ref: FPQR'; // Free Plan Quota Reached
                            // this._loadingindicator.isBusy = false;
                            // return;
                        }

                        this.checkInService.getBusiness(this.selBusinessInfo?.placeId)
                        .then( (bdata: IBusiness) => {
                            this.selBusiness = bdata;
                            this.checkInService
                                .getDepartmentInfo(this.selBusinessInfo.placeId, this.departmentId)
                                .subscribe( (dData: IDepartment) => {
                                    this.selDepartment = dData;
                                });
                            this.queueInfo$ = this.checkInService
                                .getDepartmentQueue(this.selBusinessInfo.placeId, this.departmentId);
                            this.activeQueue$ = this.queueInfo$.pipe(
                                map( entries => entries.filter(item => item.status === 'Active')),
                                map( entries => entries.sort( (x, y) => x.checkInNum - y.checkInNum))
                            );
                            this.waitQueue$ = this.queueInfo$.pipe(
                                map( entries => entries.filter(item => item.status === 'Waiting')),
                                map( entries => entries.sort( (x, y) => x.checkInNum - y.checkInNum)),
                                map( entries => {
                                    if ( ! this.selDepartment.allowLobbyWait) {
                                        return entries;
                                    }
                                    let avlCapacity = this.selDepartment.maxLobbyCount;
                                    entries.forEach((entry, index, _) => {
                                        if ( avlCapacity - entry.headCount >= 0) {
                                            entry['enterLobby'] = true;
                                            avlCapacity -= entry.headCount;
                                        } else {
                                            entry['enterLobby'] = false;
                                        }
                                    });
                                    return entries;
                                })
                            );
                        });

                    });
            }
        });
    }


}
