import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

export interface PhoneNumberSelectedEvent {
  phoneNumber: string;
  valid: boolean;
}

interface IntlTelInput {
  getNumber: () => string;
  isValidNumber: () => boolean;
}

@Directive({
  selector: '[intlTelInput]',
})
export class IntlTelInputDirective implements OnInit {

  @Output() selectPhoneNumber = new EventEmitter<PhoneNumberSelectedEvent>();
  @Input() country: string;
  private readonly defaultUtilScript = 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js';
  private readonly inputElement: HTMLInputElement;
  
  private intlTelInput!: IntlTelInput;

  constructor(el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  ngOnInit(): void {
    this.intlTelInput = (window as any).intlTelInput(this.inputElement, {
      utilsScript: this.defaultUtilScript,
      separateDialCode: true,
      preferredCountries: ['us', 'ca'],
      initialCountry: this.country
    });
    this.inputElement.addEventListener('input', (event: Event) => {
      // @ts-ignore
      const value = event.target.value;
      this.inputElement.value = value.replace(/[^0-9+]/g, '');
    });
  }

  @HostListener('blur') onBlur(): void {
    this.selectPhoneNumber.next({
      phoneNumber: this.intlTelInput.getNumber(),
      valid: this.intlTelInput.isValidNumber()
    });
  }


}