import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WebCheckInComponent } from './components/webcheckin/web-check-in.component';
import { QueueStatusComponent } from './components/webcheckin/queueStatus/queue-status.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';


const routes: Routes = [
  { path: 'queue', component: QueueStatusComponent },
  { path: 'dashboard/:business', component: DashboardComponent },
  { path: 'dashboard/:business/:department', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: ':business', component: WebCheckInComponent },
  { path: ':business/:department', component: WebCheckInComponent, runGuardsAndResolvers: 'always' },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
