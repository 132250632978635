import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { LoadingService } from 'src/app/services/loading.service';
declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isLandingPage = false;

    constructor(
        private _router: Router,
        private _loadingIndicator: LoadingService
    ) {
        console.log(this._router.url);
        // Different navbars on landing page and regular pages
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            const currentRoute = event.url;
            this.isLandingPage = currentRoute === '/' || currentRoute.startsWith('/#') ? true : false;
        });
    }

    ngOnInit() { }

}
