import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreCollectionGroup, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';

import { IBusinessInfo } from '../models/business';
import { IQuestion } from '../models/question';
import { ICheckInUser } from '../models/check-in-user';
import { IDepartment } from '../models/department';
import { IService } from '../models/service';
import { IQueueInfo } from '../models/queue-info';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CheckInService {

    private businessCollection: AngularFirestoreCollection<any>;
    private busRegInfoCollection: AngularFirestoreCollection<any>;
    constructor(
        private db: AngularFirestore,   // Inject Firestore service
        private funcs: AngularFireFunctions // Inject cloud functions
    ) {
        this.businessCollection = this.db.collection('businesses');
        this.busRegInfoCollection = this.db.collection('businessesRegInfo');
    }

    public getBusiness(placeId: string) {
        if (placeId == null) { return Promise.resolve(null); }
        const docRef = this.businessCollection.doc(placeId);
        return docRef.get().pipe(
            map(doc => doc.data())
        ).toPromise();
    }

    public getBusinessRegInfo(id: string) {
        if (id == null) { return Promise.resolve(null); }
        if (id.length === 6) // bid
        {
            return this.getBusinessRFromBId(id);
        } else { // doc id
            return this.getBusinessRFromDocId(id);
        }
    }

    private getBusinessRFromDocId(placeId: string) {
        if (placeId == null) { return Promise.resolve(null); }
        const docRef = this.busRegInfoCollection.doc(placeId);
        return docRef.get().pipe(
            map(doc => {
                const busData = doc.data();
                if (busData.isActive && busData.planIsACtive) {
                    return busData;
                } else {
                    return null;
                }
            })
        ).toPromise();
    }


    private getBusinessRFromBId(bId: string) {
        const colRef = this.db.collection<IBusinessInfo>('businessesRegInfo',
            ref => ref.where('bid', '==', bId)
                .where('isActive', '==', true)
                .where('planIsActive', '==', true)
        );
        return colRef.get().pipe(
            map(doc => doc.docs[0]?.data())
        ).toPromise();
    }

    public getDepartmentFromId(placeId: string, departmentId: string) {
        let colRef: AngularFirestoreCollection;
        let docRef: AngularFirestoreDocument;
        if (departmentId.trim() !== '') { // Specified departments
            docRef = this.db.doc('businessesRegInfo/' + placeId + '/departments/' + departmentId);
            return docRef.get().pipe(
                map(doc => {
                    const depData = doc.data();
                    if (depData && depData.qModeActive) {
                        return [{ id: doc.id, ...depData }];
                    } else {
                        return [];
                    }
                })
            ).toPromise();
        } else { // all departments
            colRef = this.db.collection('businessesRegInfo/' + placeId + '/departments',
                ref => ref.where('qModeActive', '==', true));
            return colRef.get().pipe(
                map(res =>
                    res.docs.map(a => {
                        const depdata = a.data();
                        const id = a.id;
                        return { id, ...depdata };
                    })
                )
            ).toPromise();
        }
    }

    public getServices(placeId: string, department: string) {
        let colRef: AngularFirestoreCollection;
        colRef = this.db.collection('businessesRegInfo/' + placeId
            + '/departments/'
            + department
            + '/services',
            ref => ref.where('enable', '==', true)
                .where('visibility', '==', true)
        );
        return colRef.get().pipe(
            map(res =>
                res.docs.map(a => {
                    const serdata = a.data();
                    const id = a.id;
                    return { id, ...serdata, selected: false };
                })
            )
        ).toPromise();
    }

    public getDepartmentInfo(placeId: string, departmentId: string) {
        let docRef: AngularFirestoreDocument;
        docRef = this.db.doc('businessesRegInfo/' + placeId
            + '/departments/'
            + departmentId
        );
        return docRef.valueChanges();
    }

    public getQuestions(placeId: string, department: string, service: string) {
        let colRef: AngularFirestoreCollection;
        colRef = this.db.collection('businessesRegInfo/' + placeId
            + '/departments/'
            + department
            + '/services/'
            + service
            + '/questions'
        );
        return colRef.get().pipe(
            map(res =>
                res.docs.map(a => {
                    const qData = a.data();
                    const id = a.id;
                    return { id, ...qData, answer: '' } as IQuestion;
                })
            )
        ).toPromise();
    }

    public addToQueue(user: ICheckInUser, placeId: string, departmentDocID: string, services: IService[]) {
        const callable = this.funcs.httpsCallable('onCheckinQ');
        const serviceCategories = [];
        services.forEach(item => {
            const serviceObject = {} as any;
            serviceObject.name = item.name;
            item.questions.forEach(question => {
                const key = (question.mandatory ? 'T' : 'F') + '{|}' + question.question.trim();
                const value = question.answer;
                serviceObject[key] = value;
            });
            serviceCategories.push(serviceObject);
        });
        const postData = callable({
            name: user.name,
            phoneNumber: user.phoneNumber,
            email: user.email,
            placeId,
            departmentDocID,
            headCount: user.headCount,
            notifyUsing: user.notifyUsing,
            checkinMethod: 'Web',
            serviceCategories
        });
        // console.log('Post Data', user, serviceCategories);
        return postData.toPromise();
    }

    public removeFromQueue(placeId: string, departmentDocID: string, departmentQDocID: string) {
        const callable = this.funcs.httpsCallable('onCancelCheckinQ');
        const postData = callable({
            placeId, departmentDocID, departmentQDocID
        });
        return postData.toPromise();
    }

    public getQueueInfo(placeId: string, departmentId: string, queueId: string) {
        let docRef: AngularFirestoreDocument;
        docRef = this.db.doc('businessesRegInfo/' + placeId
            + '/departments/'
            + departmentId
            + '/q/'
            + queueId);
        return docRef.valueChanges();
    }

    public getQueuePos(placeId: string, departmentId: string, qId: string) {
        const colRef = this.db.collection('businessesRegInfo/' + placeId
            + '/departments/'
            + departmentId
            + '/q',
            ref => ref.where('status', '==', 'Waiting'));
        return colRef.get().pipe(
            map(res =>
                res.docs
                    .map(a => {
                        const qData = a.data();
                        const queueId = a.id;
                        return { queueId, ...qData } as IQueueInfo;
                    })
                    .sort((x, y) => (x.checkInNum - y.checkInNum))
            ),
            map(x => {
                let currentHeadCount = 0;
                let currentPosition = -1;
                x.some( (queue, index, _) => {
                    currentHeadCount += queue.headCount;
                    if (queue.queueId === qId) {
                        currentPosition = index;
                        return true;
                    } else {
                        return false;
                    }
                });
                return { currentPosition, currentHeadCount}
            })
        ).toPromise();
    }

    public getDepartmentQueue(placeId: string, departmentId: string): Observable<IQueueInfo[]>{
        const colRef: AngularFirestoreCollection<IQueueInfo> = this.db.collection('businessesRegInfo/' + placeId
        + '/departments/'
        + departmentId
        + '/q',
        ref => ref.where('status', '!=', 'Complete')); // Hold, 
        return colRef.valueChanges();
    }

}
