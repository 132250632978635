import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean>;

  public set isBusy(value: boolean) {
    this.loadingSubject.next(value);
    if(value) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  constructor(private spinner: NgxSpinnerService) {
    this.loading$ = this.loadingSubject.pipe(debounceTime(300), delay(0));
  }
}
