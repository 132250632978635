<div class="container">
    <div class="row">
        <div class="justify-content-center ">
            <h1>
                <img [hidden]="selBusiness?.businessLogo.trim() ==''" height="30" style="vertical-align: middle;"
                    [src]="selBusiness?.businessLogo" />
                <span style="vertical-align: middle;">&nbsp;&nbsp;{{selBusiness?.name}}</span>
            </h1>
        </div>
        <div class="ml-auto">
            <div class="medium" *ngIf="selDepartment && selDepartment?.name != 'Default'">
                <span>Queue: {{selDepartment?.name}} {{selDepartment?.qStatus == true ? '(Open)' : '(Closed)'}}</span>
            </div>
            <div class="medium" *ngIf="selDepartment?.name == 'Default'">
                <span>Queue: Main {{selDepartment?.qStatus == true ? '(Open)' : '(Closed)'}}</span>
            </div>
        </div>
    </div>
    <div class="small">{{selDepartment?.address}}</div>
    <hr />
    <div class="row justify-content-between ">
        <div class="">
            <div class="text-center">
                &nbsp;
            </div>
            <div class="medium">&nbsp;</div>
            <div class="wrapper mt-2 ">
                <div class="content bg-grey" style="min-width: 200px;">
                    <div class="medium">Queue Length</div>
                    <div class="big bold">{{selDepartment?.queueLength}}</div>
                </div>
            </div>
            <div class="wrapper mt-4">
                <div class="content bg-grey" style="min-width: 200px;">
                    <div class="medium">Approx wait time</div>
                    <div class="big bold">{{selDepartment?.waitTime}} <span class="regular bold">mins</span></div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="text-center medium bold active">Active ({{( activeQueue$ | async)?.length}})</div>
            <div class="text-center">
                &nbsp;
            </div>            
            <div class="wrapper mt-2">
                <div class="content" style="min-height:300px;min-width: 200px;">
                    <table style="width: 100%;">
                        <thead>
                            <th>Token #</th>
                            <th>Kiosk</th>
                        </thead>
                        <tr *ngFor="let queue of activeQueue$ | async ; let i=index">
                            <td>{{queue.tokenNum}}</td>
                            <td>{{queue.kioskNo }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="">
            <div class="text-center medium bold waiting">Waiting ({{( waitQueue$ | async)?.length}})</div>
            <div class="text-center">
                &#9754; - Enter Lobby
            </div>
            <div class="wrapper mt-2">
                <div class="content" style="min-height:300px;min-width: 150px;">
                    <table style="width: 100%;">
                        <thead>
                            <th colspan="2">Token #</th>
                        </thead>
                        <tr *ngFor="let queue of waitQueue$ | async ; let i=index">
                            <td style="text-align: right;width:50%;">{{queue.tokenNum}} </td>
                            <td style="text-align: left;line-height: 1.2em;vertical-align: top;">
                                {{queue.enterLobby ? "&#9754;" : "" }}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
        <div class="">
            <div class="medium">&nbsp;</div>
            <div class="text-center" style="vertical-align: middle;">
                Check-in
                <qrcode [qrdata]="'https://app.qwaits.com/'+selBusinessInfo?.bid" [width]="200" [errorCorrectionLevel]="'M'"
                    [elementType]="'svg'"></qrcode>
            </div>
            <div></div>

        </div>
    </div>
</div>