import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IBusiness, IBusinessInfo } from 'src/app/models/business';
import { IDepartment } from 'src/app/models/department';
import { IQueueInfo } from 'src/app/models/queue-info';
import { CheckInService } from 'src/app/services/check-in.service';
import { LoadingService } from 'src/app/services/loading.service';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
    selector: 'app-queue-status',
    templateUrl: './queue-status.component.html',
    styleUrls: ['./queue-status.component.scss']
})
export class QueueStatusComponent implements OnInit {

    constructor(public router: Router,
                public route: ActivatedRoute,
                private _loadingindicator: LoadingService,
                private util: HelperService,
                private checkInService: CheckInService) { }

    public departmentId: string;
    public queueId: string;
    private lastUpdated: Date = null;
    private intervalId;
    public timeDiff = 0;
    public selBusiness: IBusiness;
    public selBusinessInfo: IBusinessInfo;
    public selDepartment: IDepartment;
    public selQueue: IQueueInfo;
    public currentPosition: number;
    public currentHeadCount: number;
    public submitted = false;

    ngOnInit(): void {
        this._loadingindicator.isBusy = true;
        const bid = this.route.snapshot.queryParamMap.get('bid');
        this.departmentId = this.route.snapshot.queryParamMap.get('departmentId');
        this.queueId = this.route.snapshot.queryParamMap.get('q');

        this.checkInService.getBusinessRegInfo(bid)
            .then((data: IBusinessInfo) => {
                this.selBusinessInfo = data;
                console.log('Business Data:', this.selBusinessInfo);
                this.checkInService.getBusiness(this.selBusinessInfo?.placeId)
                    .then((bData: IBusiness) => {
                        this.selBusiness = bData;
                    });
                this.checkInService.getDepartmentInfo(this.selBusinessInfo?.placeId, this.departmentId)
                    .subscribe((depData: IDepartment) => {
                            this.selDepartment = depData;
                            if (this.currentPosition == null || this.currentPosition > 0 ) { // If not waiting then dont calc queue position
                                this.refreshQueuePosition();
                            }
                    });
                this.checkInService.getQueueInfo(this.selBusinessInfo.placeId, this.departmentId, this.queueId)
                .subscribe(
                    (qdata: IQueueInfo) => {
                        if (qdata == null ){
                            this._loadingindicator.isBusy = false;
                            return;
                        }
                        this.selQueue = qdata;
                        this.refreshQueuePosition(); // optional call
                        if (this.lastUpdated == null) {
                            this.lastUpdated = this.selQueue.createdOn.toDate();
                            this.updateTime();
                        }
                        this._loadingindicator.isBusy = false;
                    }
                );
            });

        this.intervalId = setInterval(() => {
            this.updateTime();
        }, 60000);
    }

    private updateTime() {
        let diff = ( new Date().getTime() - this.lastUpdated.getTime() ) / 1000;
        diff /= 60;
        this.timeDiff = Math.abs(Math.round(diff));
    }

    private refreshQueuePosition() {
        this.checkInService.getQueuePos(this.selBusinessInfo.placeId, this.departmentId, this.queueId).
            then( (data) => {
                this.currentHeadCount = data.currentHeadCount;
                this.currentPosition = data.currentPosition + 1; // data value of -1 means no longer waiting
                if (this.currentPosition === 0) {
                    clearInterval(this.intervalId);
                }
            });
    }

    public async cancelCheckIn(){
        const result = await this.util.showConfirm('Confirm', 'Are you sure you wish to cancel check-in?');
        if (result === false ) {
            return;
        }
        this.submitted = true;
        this._loadingindicator.isBusy = true;
        this.checkInService.removeFromQueue(this.selBusinessInfo?.placeId, this.departmentId, this.queueId)
            .then( (data) => {
                if ( data.CODE === '0') {
                    console.log('Check-in cancelled');
                } else {
                    console.log('Could not add to the queue', data);
                }
            })
            .finally ( () => {
                this.submitted = false;
                this._loadingindicator.isBusy = false;
            });
    }
    public getColorByStatus( status: string) {
        switch (status) {
            case 'Waiting' : return '#e76f51';
            case 'Active' : return '#2a9d8f';
            case 'Complete' : return '#008037';
            case 'Abort' : return '#ff5757';
            case 'Hold' : return '#08afc9';

        }
    }
}
