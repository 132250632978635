import { Component, Inject } from '@angular/core';
import { LoadingService } from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'qwaitsb-web';

  constructor( public loadingIndicator: LoadingService) { }

}
