<div class="container">
    <div class="row justify-content-center">
        <h1>
            <img [hidden]="selBusiness?.businessLogo.trim() ==''" height="30" style="vertical-align: middle;" [src]="selBusiness?.businessLogo"/>
            <span style="vertical-align: middle;">&nbsp;&nbsp;{{selBusiness?.name}}</span>
        </h1>
    </div>
    <hr />
    <div class="row">
        <div class="col-8">
            <div class="department" *ngIf="selDepartment?.name != 'Default'">
                Queue for: <span style="font-weight: bolder;">{{selDepartment?.name}}</span>
            </div>
            <div class="department" *ngIf="selDepartment?.name == 'Default'">
                <span style="font-weight: bolder;">Queue (Main)</span>
            </div>
        </div>
        <div class="col-4">
            <div class="qStatus" [ngStyle]="{'color':selDepartment?.qStatus == true ? 'green' : 'red'}">
                <span class="dot" style="margin-right: 5px;"
                    [ngStyle]="{'background-color':selDepartment?.qStatus == true ? 'green' : 'red'}">
                </span>
                {{selDepartment?.qStatus == true ? 'Open' : 'Closed'}}
            </div>
        </div>
    </div>
    <div class="address">{{selDepartment?.address}}</div>
    <br />
    <div class="container top-buffer">
        <div class="row">
            <div class="col-4 text-center status-label">
                You are in
            </div>
            <div class="col-4 text-center status-label">
                Status
            </div>
            <div class="col-4 text-center status-label">
                Total queue
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-4 text-center">
                <div class="youAreIn">{{ selQueue?.status == 'Waiting' ? currentPosition : '-'}}</div>
            </div>
            <div class="col-4 text-center" *ngIf="selQueue?.status == 'Active'">
                <div class="queueStatus blink" [ngStyle]="{'color': getColorByStatus(selQueue?.status)}">{{selQueue?.status}}</div>
            </div>
            <div class="col-4 text-center" *ngIf="selQueue?.status != 'Active'">
                <div class="queueStatus" [ngStyle]="{'color': getColorByStatus(selQueue?.status)}">{{selQueue?.status}}</div>
            </div>
            <div class="col-4 text-center">
                <div class="totalQueue">{{ selQueue?.status == 'Waiting' ? selDepartment?.queueLength : '-'}}</div>
            </div>
        </div>
        <div class="row top-buffer justify-content-center statusMessage">
            <div *ngIf="selQueue?.status == 'Waiting' && currentPosition > 1" style="margin-top:auto; margin-bottom: auto;">Your approx wait time: &nbsp;<span class="waitTime">{{(selDepartment?.waitTime * (currentPosition - 1))}}</span>&nbsp; mins</div>
            <div class="waitTime" *ngIf="selQueue?.status == 'Waiting' && currentPosition == 1" style="margin-top:auto; margin-bottom: auto;">You are next in line.</div>
        </div>
        <div class="row top-buffer justify-content-center statusMessage">
            <div [ngStyle]="{'color': getColorByStatus(selQueue?.status)}" *ngIf="selQueue?.status == 'Waiting' && selDepartment?.allowLobbyWait == true && (selDepartment?.maxLobbyCount >= currentHeadCount)" style="margin-top:auto; margin-bottom: auto;">You can enter the lobby and wait.</div>
        </div>
        <div class="row top-buffer justify-content-center statusMessage minAgo" *ngIf="selQueue?.status == 'Waiting' || selQueue?.status == 'Hold'">
            Checked in {{timeDiff == 0 ? 'a moment' : timeDiff + ' min ' }} ago
        </div>
        <div class="row top-buffer justify-content-center statusMessage" style="font-weight: bolder;" *ngIf="selQueue?.status == 'Active'">
            It is your turn now.
        </div>
        <div class="row top-buffer justify-content-center statusMessage" style="font-weight: bolder;" *ngIf="selQueue?.status == 'Active' && selQueue?.kioskNo.trim() != ''">
            Please go to kiosk# {{selQueue?.kioskNo}}
        </div>
        <div class="row top-buffer justify-content-center" *ngIf="selQueue?.status == 'Waiting' || selQueue?.status == 'Hold' || selQueue?.status == 'Active'">
            <qrcode [qrdata]="departmentId+'|'+queueId" [width]="256" [errorCorrectionLevel]="'M'"
                [elementType]="'svg'"></qrcode>
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <div class="statusMessage">Token # {{selQueue?.tokenNum}}</div>
            </div>
        </div>
        <div class="row top-buffer justify-content-center" *ngIf="selQueue?.status == 'Waiting'">
            <button type="button" class="btn btn-info button-size" (click)="cancelCheckIn()"
                [disabled]="submitted == true">
                Cancel check-in
            </button>
        </div>
    </div>
    <div class="row top-buffer justify-content-center text-center" style="margin-top: 100px;" *ngIf="selQueue?.status === 'Complete'">
        {{selDepartment?.thankUMsg.trim() === '' ? '' : selDepartment?.thankUMsg}}
    </div>
    <br />
</div>