<app-navbar></app-navbar>
<main class="flex-grow-1">
    <div *ngIf="loadingIndicator.loading$ | async">
        <ngx-spinner></ngx-spinner>
    </div>
    <div >
    <router-outlet></router-outlet>
  </div>
</main>
<app-footer></app-footer>
