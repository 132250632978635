import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CheckInService } from 'src/app/services/check-in.service';
import { PhoneNumberSelectedEvent } from './tel-directive';

import { IBusiness, IBusinessInfo } from 'src/app/models/business';
import { ICheckInUser } from 'src/app/models/check-in-user';
import { IDepartment } from 'src/app/models/department';
import { IQuestion } from 'src/app/models/question';
import { IService } from 'src/app/models/service';
import { IQResponse } from 'src/app/models/qresponse';
import { LoadingService } from 'src/app/services/loading.service';
import { forkJoin } from 'rxjs';
import { HelperService } from 'src/app/utils/helper.service';

@Component({
    selector: 'app-web-checkin',
    templateUrl: './web-check-in.component.html',
    styleUrls: ['./web-check-in.component.scss']
})
export class WebCheckInComponent implements OnInit {

    constructor(public router: Router,
                public route: ActivatedRoute,
                private _loadingindicator: LoadingService,
                private util: HelperService,
                private checkInService: CheckInService) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    private businessId: string;
    private departmentId: string;
    public selBusinessInfo: IBusinessInfo;
    public selBusiness: IBusiness;
    public selDepartment: IDepartment;
    public selService: IService;
    public checkInUser: ICheckInUser = {
        headCount: 1, name: '', email: '',
        localPhoneNumber: '', phoneNumber: '',
        notifyUsing: 1
    } as ICheckInUser;
    public lstDepartments: IDepartment[];
    public submitted = false;
    public currentStep = 1;
    private queueId: string;
    //
    public busError: string;
    public checkInError: string;

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.businessId = params.get('business');
            this.departmentId = params.get('department') || '';
            this.queueId = this.route.snapshot.queryParamMap.get('q');
            if (this.queueId) {
                this.router.navigate(['/queue'], {
                    queryParams: {
                        bid: this.businessId,
                        departmentId: this.departmentId,
                        q: this.queueId
                    }, skipLocationChange: true
                });
                return;
            }
            this._loadingindicator.isBusy = true;
            this.checkInService.getBusinessRegInfo(this.businessId)
                .then((data: IBusinessInfo) => {
                    this.selBusinessInfo = data;
                    console.log('Business Data:', this.selBusinessInfo);
                    // Bussiness logic
                    if ( !this.selBusinessInfo || !this.selBusinessInfo.planIsActive || !this.selBusinessInfo.isActive) {
                        this.busError = 'Web check-in not available. Please contact the business. Ref: BNAOPE'; // This business is no longer active or the plan has expired
                        this._loadingindicator.isBusy = false;
                        return;
                    }
                    if (this.selBusinessInfo.planId === '0free' &&
                        this.selBusinessInfo.currentQueueLimit >= this.selBusinessInfo.maxQueueLimit ){
                        this.busError = 'Web check-in not available. Please contact the business. Ref: FPQR'; // Free Plan Quota Reached
                        this._loadingindicator.isBusy = false;
                        return;
                    }

                    const q1 = this.checkInService.getBusiness(this.selBusinessInfo?.placeId);
                    const q2 = this.checkInService.getDepartmentFromId(this.selBusinessInfo?.placeId, this.departmentId);
                    forkJoin([q1, q2]).subscribe(results => {
                        const bData = results[0] as IBusiness;
                        const dData = results[1] as IDepartment[];
                        this.selBusiness = bData;
                        this.lstDepartments = dData.sort((a, b) => a.name.localeCompare(b.name));
                        if(this.lstDepartments.length == 0 ){
                            console.log('No matching departments found');
                            this._loadingindicator.isBusy = false;
                            return;
                        }
                        console.log('Department data', this.lstDepartments);
                        this.lstDepartments.forEach(element => {
                            this.checkInService.getServices(this.selBusinessInfo?.placeId, element.id)
                                .then((sdata: IService[]) => {
                                    element.services = sdata.sort((a, b) => a.name.localeCompare(b.name));
                                })
                                .finally( () => {
                                    this._loadingindicator.isBusy = false;
                                });
                        });
                    });

                });
        });

    }

    public selectDepartment(department: IDepartment) {
        this.selDepartment = department;
        console.log('Selected queue:', department);
        this.selDepartment.services.forEach(service => {
            this.checkInService.getQuestions(this.selBusinessInfo.placeId, this.selDepartment.id, service.id)
                .then((data: IQuestion[]) => {
                    service.questions = data.sort((a, b) => (a > b ? 1 : -1));
                    console.log(data);
                });
        });
        this.currentStep = 2;
    }

    public checkInAllowed(department: IDepartment): boolean {
        // WebCheckin not allowed as per plan
        if (this.selBusinessInfo.webCheckInAllowed === false) {
            department.deptError = 'Web check-in has been disabled.';
            return false;
        }

        // WebCheckin Disabled either by business or by department
        if (department.inheritFromBusiness === true) {
            if ( this.selBusinessInfo.enableWebCheckIn === false) {
                department.deptError = 'Web check-in is disabled.';
                return false;
            }
        } else {
            if (department.enableWebCheckIn === false) {
                department.deptError = 'Web check-in is disabled.';
                return false;
            }
        }

        // Allow Business check in only
        if(department.inheritFromBusiness == true) {
            if(this.selBusiness.enableBusinessCheckinOnly === true) {
                department.deptError = 'Please contact business to check in.';
                return false;
            }
        } else {
            if (department.enableBusinessCheckinOnly) {
                department.deptError = 'Please contact business to check in.';
                return false;
            }
        }

        // Queue Closed
        if (department.qStatus === false) {
            department.deptError = department.closeMsg.trim() === '' ?
                (this.selBusiness.closeMsg.trim() === 'Queue is closed!' ? '' : this.selBusiness.closeMsg) : department.closeMsg;
            return false;
        }
        department.deptError = '';
        return true;
    }

    public smsAllowed(department: IDepartment): boolean {
        // smsEnabled is false, do not show SMS & both
        if (this.selBusinessInfo.smsEnabled === false) {
            console.log('smsEnable = false');
            return false;
        }

        // if business phone number is not available (this is required to send sms), do not show SMS & both
        if (this.selBusinessInfo.phoneNumber.trim() === '') {
            console.log('Business phone number not available.');
            return false;
        }

        // sms notification is not required for web check-in or sms notification is turned off for web check-in
        if (department.inheritFromBusiness === true) {
            if ( this.selBusinessInfo.enableNotifWebCheckIn === false || this.selBusinessInfo.enableSMSNotification === false ) {
                console.log('inherited: sms notification is not required for web check-in or sms notification is turned off for web check-in');
                return false;
            }
        } else {
            if (department.enableNotifWebCheckIn === false || department.enableSMSNotification === false) {
                console.log('not inherited: sms notification is not required for web check-in or sms notification is turned off for web check-in');
                return false;
            }
        }

        // smsCredits is 0 or smsCreditsUsage is greater or equal to 0
        if (this.selBusinessInfo.smsCredits === 0 || (this.selBusinessInfo.smsCreditsUsage >= this.selBusinessInfo.smsCredits)) {
            console.log('smsCredits is 0 or smsCreditsUsage is greater or equal to 0');
            return false;
        }

        department.deptError = '';
        return true;
    }

    public serviceSelection($event) {
        $event.stopPropagation();
    }

    public getIntlNumber($event: PhoneNumberSelectedEvent) {
        if ($event.valid) {
            this.checkInUser.phoneNumber = $event.phoneNumber;
        }
    }

    public processCheckIn() {
        console.log('User Details', this.checkInUser);
        this.submitted = true;
        this.checkInError = '';
        // check user info errors
        if (this.checkInUser.name.trim() === '') {
            this.checkInError = 'Contact Name is required';
            this.submitted = false;
            return;
        }
        if (this.checkInUser.email.trim() === '' && this.checkInUser.localPhoneNumber.trim() === '') {
            this.checkInError = 'Either email or phone number is required for check in notification.';
            this.submitted = false;
            return;
        }
        if (this.checkInUser.email.trim() !== '') {
            // validate email
        }
        if (this.checkInUser.localPhoneNumber.trim() !== '') {
            // validate phone
            if (this.checkInUser.phoneNumber.trim() === '') {
                this.checkInError = 'Phone number entered is not valid';
                this.submitted = false;
                return;
            }
        } else {
            this.checkInUser.phoneNumber = '';
        }
        // validate headcount
        if (this.selDepartment?.allowMorePeopleInACheckin) {
            if (this.checkInUser.headCount === 0 || this.checkInUser.headCount > this.selDepartment.maxHeadCount) {
                if (this.selDepartment.maxHeadCount === 1) {
                    this.checkInError = 'Only one person can checkin at a time. Please correct and retry.';
                } else {
                    this.checkInError = 'Total people checking in has to be between 1 and ' + this.selDepartment.maxHeadCount + '. Please correct and retry.';
                }
                this.submitted = false;
                return;
            }
        }
        // check service errors
        const selServices = this.selDepartment.services.filter(x => x.selected);
        if (this.selDepartment.services.length > 0 && selServices.length === 0) {
            this.checkInError = 'Atleast one service needs to be selected for check in';
            this.submitted = false;
            return;
        }
        let valid = true;
        for (const service of selServices) {
            for (const question of service.questions) {
                if (question.mandatory && question.answer.trim() === '') {
                    valid = false;
                    break;
                }
            }
            if (!valid) {
                this.checkInError = service.name + ' service has mandatory questions. Please answer all mandatory questions.';
                this.submitted = false;
                return;
            }
        }
        // Notify using
        if (this.checkInUser.email.trim() === '' && ( this.checkInUser.notifyUsing === 3 || this.checkInUser.notifyUsing === 1 ) ) {
            this.checkInError = 'For email notification an email address is required';
            this.submitted = false;
            return;
        }
        if (this.checkInUser.phoneNumber.trim() === '' && (this.checkInUser.notifyUsing === 3 || this.checkInUser.notifyUsing === 2)) {
            this.checkInError = 'For SMS notification phone number is required';
            this.submitted = false;
            return;
        }
        if ( this.checkInUser.notifyUsing === 3 && (this.checkInUser.email.trim() === '' || this.checkInUser.phoneNumber.trim() === '') ) {
            this.checkInError = 'For both email and SMS notification, a valid email address and phone number is required';
            this.submitted = false;
            return;
        }
        // All valid
        this._loadingindicator.isBusy = true;
        this.checkInService.addToQueue(this.checkInUser, this.selBusinessInfo.placeId,
            this.selDepartment.id, this.selDepartment.services.filter(x => x.selected))
            .then( (data: IQResponse) => {
                if ( data.CODE === '0') {
                    console.log('Added to queue response', data);
                    const url = new URL(data.qurl);
                    this.router.navigate(['/' + this.businessId + '/' + this.selDepartment.id], {
                        queryParams: {
                            q: url.searchParams.get('q')
                        }, skipLocationChange: false
                    });
                    return;
                } else {
                    this.checkInError = "Could not add to the queue. Please contact business.";
                    if (data.CODE === '-3' ){
                        this.util.showAlert('Error Occurred', data.data);
                    } else if(data.CODE === '-2') {
                        this.util.showAlert('Error Occurred', data.data);
                        this.selDepartment.maxHeadCount = data.maxHeadCount;
                    }
                    console.log('Could not add to the queue', data);
                }
            })
            .catch(err => {
                console.log('Error from add to queue', err);
            })
            .finally ( () => {
                this._loadingindicator.isBusy = false;
                this.submitted = false;
            });
    }
}
